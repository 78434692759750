<template>
  <!--创建地图容器元素-->
  <div>
    <van-nav-bar fixed left-arrow @click-left="$router.push('/ruralFilm')">
      <template #right>
        <van-button type="info" round style="width: 8vw" size="mini">
          <template #icon>
            <van-icon color="#fff" name="search"/>
          </template>
        </van-button>
      </template>
      <template #title>
        <input
            type="text"
            class="searchInput"
            @focus="$router.push('/ruralFilm/searchPoint')"
            placeholder="搜寻更多观影点"
        />
      </template>
    </van-nav-bar>
    <div id="tdtMapDivID" ref="tdtMapDivID" class="divTdtMap"></div>
    <div class="location">
      <!-- 当前放映点位信息 -->
      <div>
        <span>您当前所在位置</span>
        <span>
          <van-icon name="location" color="#1492FF"/>
          <span>{{ userPoint.name }}</span>
        </span>
        <span style="color: #999; font-size: 12px;text-indent: 0.4rem">{{ userPoint.address }}</span>
      </div>
      <div>
        <!--        更改放映点-->
        <a style="color: #1492ff" @click="toSelectPoint"> 更改放映点 </a>
      </div>
    </div>
    <!-- 放映点列表 -->
    <div class="cinemaList">
      <div class="cinemaTitle">推荐观影点</div>
      <div
        class="cinemaItem box"
        v-for="i in mapData.surrounds"
        :key="i.id"
        :class="{'cinemaItem-selected': selectedPoint && selectedPoint.id === i.id}"
      >
        <div class="cinemaItem-header">
          <div v-if="i.meter">距离{{ (i.meter / 1000).toFixed(2) }}公里</div>
          <div v-else style="color: #999;">
            当前位置
          </div>
          <div @click="toPointDetail(i)">
            查看放映计划
            <van-icon name="arrow"/>
          </div>
        </div>
        <div class="cinemaItem-body" >
          <!-- 点名 -->
          <div class="cinemaItem-name">
            {{ i.name }}
          </div>
          <!-- 地址 -->
          <div class="cinemaItem-address">地址：{{ i.address }}</div>
        </div>
      </div>
    </div>

    <!-- 添加确认按钮 -->
    <div class="confirm-btn" v-if="selectedPoint">
      <van-button
        type="info"
        block
        round
        @click="confirmSelect"
      >
        确认选择此放映点
      </van-button>
    </div>
  </div>
</template>
<script>
import {api} from '@/api'
import {Dialog, Toast} from "vant";
import { SetPvAndUv } from '@/mixins/PvAndUv'

export default {
  name: 'TdtMap',
  mixins: [SetPvAndUv],
  data() {
    return {
      userPoint: {},
      // 中心点
      centerPoint: {
        lng: 119.9201,
        lat: 31.068228,
        name: '后洋村',
      },
      mapData: {
        regionArea: {
          lng: 119.9201,
          name: '后洋村',
          id: 100408,
          lat: 31.068228,
        },
        lng: 119.9201,
        surrounds: [],
        lat: 31.068228,
      },
      selectedPoint: null, // 新增：当前选中的放映点
    }
  },
  created() {
  },
  mounted() {
    // 获取用户当前放映点
    this.userPoint = this.$store.state.userPoint || null
    if (!this.userPoint) {
      this.$router.replace({
        path: '/ruralFilm/selectPoint',
        query: {
          from: 'pointMap',
          back: true,
        },
      })
    }
    // 初始化天地图
    this.getSurrounds().then((res) => {
      console.log(res)
      const {success, result} = res
      if (success) {
        const {surrounds} = result
        this.mapData = {
          ...this.result,
          // 去掉第一个放映点
          surrounds: surrounds,
        }
        console.log({surrounds})
        this.centerPoint = this.userPoint
        this.initTdtMap()
      }
    })
  },
  watch: {},
  methods: {
    // 初始化天地图
    initTdtMap() {
      let T = window.T
      const tdtMapDivID = this.$refs.tdtMapDivID

      this.tdtMap = new T.Map(this.$refs.tdtMapDivID)

      // 修改缩放级别为9，以显示更大范围
      // 天地图缩放级别：1-18
      // 级别越小，显示范围越大
      // 9级大约可以显示100km范围
      this.tdtMap.centerAndZoom(
        new T.LngLat(this.centerPoint.lng, this.centerPoint.lat),
        9  // 改为9级，确保能完整显示50km范围
      )

      const centerPoint = new T.LngLat(
        this.centerPoint.lng,
        this.centerPoint.lat,
      )

      // 中心点标记
      const icon = new T.Icon({
        iconUrl: 'https://img.dev.zhijiangfilm.com/i/2024/10/12/6709d24c2c2cd.png',
        iconSize: new T.Point(30, 30),
        iconAnchor: new T.Point(10, 25),
      })
      const marker = new T.Marker(centerPoint, {icon: icon})

      // 中心点标签
      const label = new T.Label({
        text: this.centerPoint.name,
        position: centerPoint,
        zindex: 1,
        offset: new T.Point(-30, -40),
      })

      this.tdtMap.addOverLay(label)
      this.tdtMap.addOverLay(marker)

      // 修改圆形范围样式，使其更容易看清
      const circle = new T.Circle(
        new T.LngLat(this.centerPoint.lng, this.centerPoint.lat),
        50000, // 50km
        {
          fillColor: '#1492ff',
          fillOpacity: 0.08,    // 降低填充透明度
          color: '#1492ff',     // 边框颜色
          weight: 2,            // 加粗边框
          opacity: 0.6,         // 边框透明度
          dashArray: '10,10'    // 添加虚线效果
        },
      )
      this.tdtMap.addOverLay(circle)

      // 添加地图控件
      // 添加缩放控件
      this.tdtMap.addControl(new T.Control.Zoom({
        position: T_ANCHOR_TOP_RIGHT,
        zoomInText: '+',
        zoomOutText: '-'
      }));

      // 添加比例尺控件
      this.tdtMap.addControl(new T.Control.Scale({
        position: T_ANCHOR_BOTTOM_LEFT
      }));

      // 添加周边放映点标记
      for (let i = 0; i < this.mapData.surrounds.length; i++) {
        const point = new T.LngLat(
          this.mapData.surrounds[i].lng,
          this.mapData.surrounds[i].lat,
        )

        // 使用不同的图标区分周边放映点
        const surroundIcon = new T.Icon({
          iconUrl: 'https://img.dev.zhijiangfilm.com/i/2024/10/12/6709d24c2c2cd.png', // 可以使用不同的图标
          iconSize: new T.Point(24, 24),  // 稍微小一点
          iconAnchor: new T.Point(12, 24),
        })

        const marker = new T.Marker(point, {icon: surroundIcon})
        this.tdtMap.addOverLay(marker)

        const label = new T.Label({
          text: this.mapData.surrounds[i].name,
          position: point,
          offset: new T.Point(-45, -60),
          data: this.mapData.surrounds[i],  // 存储放映点数据
        })

        // 添加点击事件
        label.addEventListener('click', () => {
          this.handlePointClick(this.mapData.surrounds[i])
        })

        this.tdtMap.addOverLay(label)
      }
    },

    // 处理放映点点击
    handlePointClick(point) {
      Dialog.confirm({
        title: point.name,
        message: `距离您${(point.meter / 1000).toFixed(2)}公里\n${point.address}`,
        confirmButtonText: '查看详情',
        cancelButtonText: '取消'
      }).then(() => {
        this.toPointDetail(point)
      }).catch(() => {})
    },

    // 获取周边放映点
    getSurrounds() {
      return api.getFilmAroundPoints({...this.userPoint,kilometer:50})
    },
    // 跳转到选择放映点页面
    toSelectPoint() {
      this.$router.replace({
        path: '/ruralFilm/selectPoint',
        query: { form: this.$route.path, back: false },
      })
    },
    // 跳转到放映点详情页面
    toPointDetail(point) {
      this.$router.push({
        path: '/ruralFilm/pointDetail/' + point.id,
        // query: { pointId: point.id },
      })
    },
    // 保存放映点
    saveUserPoint(point) {
      return false
      // Dialog.confirm({
      //   title: '确认切换放映点？',
      //   message: `您将切换到${point.name}放映点`,
      //   confirmButtonText: '确定',
      //   confirmButtonColor: '#1492ff',
      //   cancelButtonText: '取消',
      //   callback: (action) => {
      //     if (action === 'confirm') {
      //       this.$store.dispatch('changeuserPoint', point)
      //       this.userPoint = point
      //       Toast('切换成功')
      //       this.initTdtMap()
      //     }
      //   },
      // })
    },

    // 高亮显示选中的放映点
    highlightPoint(point) {
      // 如果地图已初始化
      if (this.tdtMap) {
        // 将地图中心移动到选中的点
        this.tdtMap.panTo(new T.LngLat(point.lng, point.lat));

        // TODO: 可以添加其他视觉效果，比如放大图标等
      }
    },

    // 确认选择
    confirmSelect() {
      if (!this.selectedPoint) {
        Toast('请先选择放映点');
        return;
      }

      Dialog.confirm({
        title: '确认选择',
        message: `您确定要选择 ${this.selectedPoint.name} 作为放映点吗？`,
        confirmButtonText: '确定',
        confirmButtonColor: '#1492ff',
        cancelButtonText: '取消',
      }).then(() => {
        this.$store.dispatch('changeuserPoint', this.selectedPoint);
        Toast.success('设置成功');
        // 返回上一页或指定页面
        this.$router.replace({
          path: '/ruralFilm/selectPoint',
          query: { form: this.$route.path, back: true },
        });
      }).catch(() => {});
    },
  },
}
</script>

<style scoped lang="less">
.searchInput {
  width: 70vw;
  height: 44px;
  background: #ffffff91;
  border-radius: 4px;
  border: 1px solid #e6e8eb;
  padding: 10px 12px;
}

.divTdtMap {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 45vh;  // 增加地图高度，以便更好地显示范围
  z-index: 0;
  //box-shadow: -1px 0px 30px #333333 inset;
}

.location {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 90px;
  border-top: 1px solid #eee;
  color: #363a44;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: @font-size-base;

    & > span:first-child {
      font-size: @font-size-large;
      font-weight: 500;
      color: #363a44;
    }
  }
}

.cinemaTitle {
  padding: 10px;
  font-size: @font-size-medium;
  color: #363a44;
  font-weight: 500;
}

.cinemaItem {
  border-bottom: 1px solid #eee;
  margin: 12px auto 0 auto;
  width: 95vw;
  height: 117px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e8e9ec;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: @font-size-medium;
    font-weight: 500;
    margin: 12px;
    color: #666;

    & > div:first-child {
      color: #1492ff;
    }
  }

  &-body {
    border-top: 1px solid #eee;
    padding: 10px 0;
    font-size: @font-size-base;
    padding: 12px;
  }

  &-name {
    font-weight: 500;
    color: #333;
  }

  &-address {
    font-size: @font-size-medium;
    color: #686b73;
  }

  // 添加选中状态样式
  &-selected {
    border: 2px solid #1492ff;
    transform: scale(1.01);
    transition: all 0.3s ease;
  }
}

.cinemaList {
  margin-top: 10px;
  padding: 0 10px;
  max-height: 50vh;
  overflow-y: auto;
  margin-bottom: 80px;  // 为底部按钮留出空间
}

::v-deep .van-nav-bar--fixed {
  top: 2vh;
  background-color: transparent;
}

::v-deep .van-nav-bar__title {
  max-width: 70%;
}

.confirm-btn {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  z-index: 999;
  padding: 0 16px;

  .van-button {
    height: 44px;
    font-size: 16px;
    background: #1492ff;
    border: none;

    &:active {
      opacity: 0.9;
    }
  }
}
</style>
